// ========== SIH 2024 Themes ==========
const sihThemesAPI = [
  {
    id: 1,
    logo: "./images/SIHThemes/agriTech.jpg",
    themeName: "Agri-Tech",
    description:
      "Agricultural technology is refers to technology use and improvement for productivity, yield and quality in agriculture. This sector has been improved due the advancement of technology.",
  },
  {
    id: 2,
    logo: "./images/SIHThemes/eduTech.jpg",
    themeName: "Edu-Tech",
    description:
      "Educational technology refers to the use of technology to enhance and support the teaching and learning process. facilitate access to information, and create more engaging and interactive learning environments. ",
  },
  {
    id: 3,
    logo: "./images/SIHThemes/climateAndEnvironment.jpg",
    themeName: "Environment & Climate ",
    description:
      "Climate focuses on the long-term atmospheric conditions in a given region, while the environment is a broader term that includes all the surroundings, both living and non-living, in which life exists.",
  },
  {
    id: 4,
    logo: "./images/SIHThemes/healthTech.jpg",
    themeName: "Health-Tech",
    description:
      "Health technology is applying organized knowledge and skills in digital devices, medicines, procedures, vaccines, and healthcare systems to solve health problems and improve quality of life.",
  },
  
  {
    id: 5,
    logo: "./images/SIHThemes/law-order.jpg",
    themeName: "Law & Order",
    description:
      "Reducing crime rates and delivering speedy justice to the needy is a challenging task globally. It requires smart policing and effectively dealing with roadblocks in legal proceedings. ",
  },
  {
    id: 6,
    logo: "./images/SIHThemes/miscellaneous.jpg",
    themeName: "Miscellaneous",
    description:
      "Miscellaneous, indiscriminate, promiscuous refer to mixture and lack of order, and may imply lack of discernment or taste. Miscellaneous emphasizes the idea of the mixture of things of different kinds or natures.",
  },
 
  {
    id: 7,
    logo: "./images/SIHThemes/Security.jpg",
    themeName: "Security",
    description:
      "Security technology helps mitigate risk by preventing unauthorized access, identifying potential incidents, allowing fast responses, deterring criminal behavior and capturing crucial evidence in the event that a breach.",
  },
   {
    id: 8,
    logo: "./images/SIHThemes/Smart-city.png",
    themeName: "Urban Mobility",
    description:
      "Urban areas are the most complex settings in which the mobility of passengers and freight is taking place. Typical urban attributes such as density, diverse economic, cultural, political, and social functions, and land scarcity. ",
  },
  
  
];

// ========== SIH 2023 Themes ==========
const sih2k23ThemesAPI = [
  {
    id: 1,
    logo: "./images/SIHThemes/healthTech.jpg",
    themeName: "Health-Tech",
    description:
      "Health technology is applying organized knowledge and skills in digital devices, medicines, procedures, vaccines, and healthcare systems to solve health problems and improve quality of life.",
  },
  {
    id: 2,
    logo: "./images/SIHThemes/agriTech.jpg",
    themeName: "Agri-Tech",
    description:
      "Agricultural technology is refers to technology use and improvement for productivity, yield and quality in agriculture. This sector has been improved due the advancement of technology.",
  },
  {
    id: 3,
    logo: "./images/SIHThemes/cyberSecurity.jpg",
    themeName: "Cyber Security",
    description:
      "Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These cyberattacks are usually aimed at accessing, changing, or destroying sensitive information.",
  },
  {
    id: 4,
    logo: "./images/SIHThemes/eduTech.jpg",
    themeName: "Edu-Tech",
    description:
      "Educational technology refers to the use of technology to enhance and support the teaching and learning process. facilitate access to information, and create more engaging and interactive learning environments. ",
  },
  {
    id: 5,
    logo: "./images/SIHThemes/criminalDatabase.jpg",
    themeName: "Criminal Database",
    description:
      "Criminal Database is a general term that refers to a repository of information related to individuals criminal histories and activities. It is a centralized collection of records associated with law enforcement, legal proceedings, and corrections. ",
  },
  {
    id: 6,
    logo: "./images/SIHThemes/forceEngagement.jpg",
    themeName: "Force Engagement",
    description:
      "Force Engagement can be understood as the interaction or involvement of a force, which could be military, law enforcement, or even a strategic business force, in a particular situation or activity.",
  },
  {
    id: 7,
    logo: "./images/SIHThemes/socialMedia.jpg",
    themeName: "Social Media",
    description:
      "Social media refers to the means of interactions among people in which they create, share, and/or exchange information and ideas in virtual communities and networks. The Office of Communications and Marketing manages the main Facebook, Twitter, Instagram, LinkedIn and YouTube accounts.",
  },
  {
    id: 8,
    logo: "./images/SIHThemes/vehiclesTheft.jpg",
    themeName: "Vehicle Theft",
    description:
      "Vehicle theft is a criminal act involving the unauthorized taking of a motor vehicle with the intent to use or sell it. It is considered a serious offense and can lead to legal consequences for the perpetrators.",
  },
  {
    id: 9,
    logo: "./images/SIHThemes/miscellaneous.jpg",
    themeName: "Miscellaneous",
    description:
      "Miscellaneous, indiscriminate, promiscuous refer to mixture and lack of order, and may imply lack of discernment or taste. Miscellaneous emphasizes the idea of the mixture of things of different kinds or natures.",
  },
];

export default sihThemesAPI;
export { sih2k23ThemesAPI };
